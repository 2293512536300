@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
html, body, #root {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  font-family: "Comfortaa", cursive;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url("../assets/background.svg");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: #fff8f8;
}
.app__introduction {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
}
.app__introduction .logo {
  display: flex;
  width: calc(100% - 15px);
  padding-left: 15px;
  height: 80px;
  align-items: center;
}
.app__introduction .logo svg {
  width: 50px;
  height: 50px;
}
.app__introduction .content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app__introduction .content h1 {
  margin: 0;
  color: #DD3142;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
}
.app__introduction .content p {
  margin: 20px 0px;
  color: #721520;
}
.app__container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 70%;
}
.app__container .letter {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__container .letter svg {
  width: 100%;
}
.app__container .letter .background__invitation {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  top: 0;
  z-index: 1000;
}
.app__container .letter__front {
  width: calc(100% - 29.5px);
  position: absolute;
  right: 2px;
  bottom: 28px;
  z-index: 100;
}
.app__container .letter__content {
  width: 80%;
  position: absolute;
  left: calc(50% + 15px);
  transform: translateX(-50%);
}
.app__container .letter__content svg {
  width: 100%;
}
.app__container .letter__background {
  width: 100%;
  height: 100%;
  display: flex;
}
.app__container .letter__background svg {
  width: 100%;
}

